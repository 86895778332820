@import (less) "mixins.less";

/*************FORMS************/
@content: #666;
@labelColor: #666;
@inputColor: #bfbfbf;
@inputBG: #fff;
@inputBorder: @content;
@inputFocusBorder: #666;
@inputFocusBG: #fff;
@errorBG: #bfbfbf;
@errorBorder: #f10e0e;
@errorLabel: #f10e0e;


.gform_wrapper{
            .bodyfont()!important;
            width:100%!important;
            margin-top:0!important;
            label{
                .cinzel(20px)!important;
                display:block;
                color:@blueDark;
                font-weight:400!important;
                }
            .gform_ajax_spinner {
            		display:none!important;
					margin-left: 20px;				/* give it some space from the Submit button */
				
					border-radius: 50%;
					width: 30px;					/* match with height for a circle */
					height: 30px;
				}
            .ginput_complex label{
                font-size:12px!important;
                }
            
            input[type=text], textarea{
                background:#fff;
                border:1px solid @blueDark;
                padding:8px!important;
                width:100%!important;
                color:@blueDark;
                .opensans(16px);
                .transition();
                outline:none;
                &::-webkit-input-placeholder {
                       color: #fff;
                    }
                    
                    &:-moz-placeholder { /* Firefox 18- */
                       color: #fff;  
                    }
                    
                    &::-moz-placeholder {  /* Firefox 19+ */
                       color: #fff;  
                    }
                    
                    &:-ms-input-placeholder {  
                       color: #fff;  
                    }
                }
            input[type=text]:focus, textarea:focus{
            	border:1px solid @blueDark;
                background:#ededee;
                }
            
            select{
                width:100%!important;
                }
            ul.gform_fields{
                padding:0px 0 10px 0!important;
                margin:0!important;
                li{
                    padding-left:0!important;
                    padding-bottom:3px!important;
                    background:none!important;
                    margin-bottom:0!important;
                    i.fa-caret-right{display:none;}
                    }
                li.gfield.gfield_error{
                    border:none;
                    background:transparent!important;
                    padding-top:0px!important;
                    max-width:100%!important;
                    
                    label, .gfield_description{
                        color:@errorLabel!important;
                        }
                    }
                }
            }
.validation_error{
    display: none!important;
    }
.gform_footer{
    margin-top:0!important;
    padding-top:0!important;
    text-align:center;
    input[type=submit]{
        
        &:hover{
            cursor:pointer;
            }
        }
    }
    
#main-header, #footer{
	.gform_confirmation_message{
	.opensans(14px);
	color:@yellow!important;
	.inline-block();
	width:auto;
	padding:0 10px 0 10px;
	.uppercase();
	.bold();
	}
	.gform_confirmation_wrapper{
		.inline-block();
	width:auto;
	margin-bottom:10px;
	
		}
	}

#gforms_confirmation_message{
    .bodyfont();
    padding:20px;
    border:5px solid #fff;
    text-align:center;
    }
    
.gform_wrapper.two-column_wrapper {
	max-width: 100%;
}

.gform_wrapper.two-column_wrapper ul.gform_fields,
.gform_wrapper.two-column_wrapper ul.gform_column li.gsection:first-child {
	display: none;
}

.gform_wrapper.two-column_wrapper ul.gform_fields.gform_column {
	display: block;
	float:left;
}

.gform_wrapper.two-column_wrapper ul.one-half {
	margin-left: 6% !important;
	width: 47%;
}
.gform_wrapper.two-column_wrapper ul.one-half.first {
	margin-left: 0 !important;
}


.gform_wrapper .gsection {
	border-bottom: none !important;
}

.gform_wrapper .top_label li.gfield.gf_right_half+li.gsection {
	padding: 0 !important;
}

@media only screen and (max-width: 1075px) {
	
	.gform_wrapper.two-column_wrapper ul.one-half {
		width: 100%;
	}
	
	.gform_wrapper.two-column_wrapper ul.one-half {
		margin-left: 0 !important;
	}
	
	.gform_wrapper .gsection {
		display: none !important; /* remove !important if it's not needed */
	}
	
	#gform_wrapper_1 li {
		margin-bottom: 10px;
	}
	
}

@media only screen and (min-width: 641px)
{
#main-header, #main-content, #footer{
	.gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){
	max-width:100%!important;
	
	label{
		color:@yellow!important;
		}
	input{
		border:1px solid @yellow!important;
		color:@yellow!important;
		}
	.gfield_description,.validation_message{
		color:@yellow!important;
		.opensans(12px);
		.uppercase();
		font-weight:normal!important;
		padding-top:3px!important;
		}
	}
}
}
